<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :popupComponent="popupComponent"
        :basic-data="basicData"
        title="Extra Facility List"
        icon="mdi-postage-stamp"
        addButtonText="Extra Facility"
        stateendpoint="extraFacility.extraFacilities"
        :actions="actions"
    />
  </div>
</template>
<script>
import ListPage from "../components/ListPage";
import ExtraFacilityPopup from "../components/popups/ExtraFacilityPopup";

export default {
  name: 'ExtraFacilities',
  components: {ListPage, ExtraFacilityPopup},
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Extra Facilities | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Price',
          align: 'right',
          value: 'price'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        {value: 'name'},
        {value: 'price', type: 'currency'},
        {value: 'isActive'},
        {value: 'action'},
      ],
      actions: {
        load: 'loadExtraFacilities',
        create: 'storeExtraFacility',
        update: 'updateExtraFacility',
        remove: 'removeExtraFacility',
        destroy: 'destroyExtraFacility'
      },
      basicData: {
        price: 0,
        isActive: true
      }
    }
  },
  computed: {
    popupComponent() {
      return ExtraFacilityPopup;
    }
  }
}
</script>
